import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Store } from '@ngxs/store';

import { LocalStorage } from 'ngx-webstorage';

import { PlanId } from '@shared/models/plan.model';

import { PlanCustomization, PlanInfo, PlanOption, PlanPayment } from '@plans/shared/models/plans.model';

import { ZefApi } from '@shared/services/zef-api.service';
import { DialogControl } from '@shared/services/dialog-control.service';

@Injectable({
  providedIn: 'root',
})
export class PlansApi {
  @LocalStorage('prefs') accountPrefs: any | undefined;

  constructor(
    @Inject(LOCALE_ID) readonly locale: string,
    readonly store: Store,
    readonly za: ZefApi,
    readonly dc: DialogControl,
  ) {}

  public getPlans(): Observable<PlanInfo[]> {
    const locale = this.locale.split('-')[0] || 'en';

    return this.za.get<PlanInfo[]>('plans/list', { locale }, false).pipe(
      map((plans) => plans.sort((a, b) => a.priceMonthly - b.priceMonthly)),
      catchError(() => of([])),
    );
  }

  public getOptions(): Observable<PlanOption[]> {
    return this.za.get<PlanOption[]>('plans/options', void 0, false).pipe(
      catchError(() => of([])),
      map((options) => options.filter((option) => option.id !== 'extra_emails')),
    );
  }

  public getPaymentPlan(
    plan: PlanId,
    period: 'monthly' | 'yearly',
    options: PlanCustomization[],
  ): Observable<PlanPayment> {
    if (plan === 'trial_plan') {
      plan = 'plan_master';
    }

    return this.za.post<PlanPayment>('plans/payment_plan', { plan, period, options });
  }

  public contactRequest(data): Observable<boolean> {
    return this.za.post('billing/request_plan_upgrade', data).pipe(
      map(() => true),
      catchError((error) => throwError(this.handleRequestError(error))),
    );
  }

  private handleRequestError(error: HttpErrorResponse): Observable<string | number> {
    const message =
      error.error instanceof Error ? error.error.message : error.status || $localize`:@@zef-i18n-00015:Unknown Error`;

    this.dc.openErrorDialog($localize`:@@zef-i18n-00040:Server Error`, message.toString());

    return of(message);
  }
}
